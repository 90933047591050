














import { Action } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NS_POI } from '@/constants/app.constants';
import { PointOfInterest } from '@/models/position.model';
import SimpleMap from '@/components/shared/SimpleMap.component.vue';
import ConfirmationDialog from '@/components/shared/ConfirmationDialog.component.vue';
import PointOfInterestAddDialog from '@/components/points-of-interest/PointOfInterestAddDialog.component.vue';

@Component({
  name: 'PointOfInterestCard',
  components: { PointOfInterestAddDialog, ConfirmationDialog, SimpleMap },
})
export default class PointOfInterestCard extends Vue {
  @Prop() public pointOfInterest!: PointOfInterest;
  @Action('removePointOfInterest', { namespace: NS_POI }) public removePointOfInterest: any;

  public shouldEdit: boolean = false;
  public shouldRemove: boolean = false;
  public zoom: number = 10;

  public onRemove() {
    if (!this.pointOfInterest.id) {
      return;
    }
    this.removePointOfInterest({ id: this.pointOfInterest.id });
  }
}
