























































































import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { DataTableHeader } from 'vuetify';
import { NS_ALERTS } from '@/constants/app.constants';
import { REGIONAL_THRESHOLD_LEVELS } from '@/constants/filters.constants';
import { Options } from '@/models/app.model';
import { Rule } from '@/models/rule.model';
import { RulesService } from '@/services/rules.service';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import StationsSelect from '@/components/shared/StationsSelect.component.vue';
import ConfirmationDialog from '@/components/shared/ConfirmationDialog.component.vue';
import { PointOfInterest } from '@/models/position.model';

@Component({
  name: 'RulesListView',
  components: {
    ConfirmationDialog,
    LoadingBar,
    StationsSelect,
  },
})
export default class RulesListView extends Vue {
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;

  public ruleToBeRemoved: Rule | null = null;
  public shouldRemove: boolean = false;
  public isLoading: boolean = false;
  public rules: Rule[] = [];
  public tableHeaders: DataTableHeader[] = [
    { text: 'Id', value: 'id', width: 70 },
    { text: 'Name', value: 'name' },
    { text: 'Regional Thresholds', value: 'thresholds' },
    { text: 'Regional Min Level', value: 'regionalPgaThresholdType' },
    { text: 'Station Rules', value: 'stationIds' },
    { text: 'Station Min Quality', value: 'stationQualityThreshold' },
    { text: 'Min Station Threshold', value: 'stationPgaThreshold' },
    { text: 'Min Alerting Stations', value: 'minAlertingStations' },
    { text: '# POIs Associations', value: 'pointsOfInterest' },
    { text: '', value: 'actions', align: 'end', width: 150 },
  ];

  public mounted() {
    this.loadRules();
  }

  public loadRules() {
    this.isLoading = true;
    RulesService.query({ start: 0, count: 100 }).then((response) => {
      this.rules = response.alertRules;
      this.isLoading = false;
    });
  }

  public getThresholdIndex(rule: Rule) {
    return _.findIndex(REGIONAL_THRESHOLD_LEVELS, (item: Options<string>) => item.value === rule.regionalPgaThresholdType);
  }

  public getThresholdName(rule: Rule) {
    return _.get(
      _.find(REGIONAL_THRESHOLD_LEVELS, (item: Options<string>) => item.value === rule.regionalPgaThresholdType),
      'name',
    );
  }

  public onRemove() {
    if (!this.ruleToBeRemoved) {
      return;
    }

    this.isLoading = true;
    RulesService.remove(this.ruleToBeRemoved.id!).then(
      () => {
        this.isLoading = false;
        this.loadRules();
        this.ruleToBeRemoved = null;
        this.addAlert({
          type: 'success',
          header: 'Rule was deleted',
          message: 'The list will refresh',
          timeout: 5000,
        });
      },
      (error: ErrorEvent) => {
        this.isLoading = false;
        this.addAlert({
          type: 'error',
          header: 'There was a problem removing the Rule',
          message: error.message,
          timeout: 5000,
        });
      },
    );
  }

  public getRemoveMessage(rule: Rule): string {
    if (!rule) {
      return '';
    }

    if (rule.pointsOfInterest.length) {
      return `The following POIs are currently associated with the "${this.parseRemoveRuleName(
        rule,
      )}" rule, please remove the association before deleting the rule. <br/><br/>
      <b>${this.parseRemovePOIs(rule)}</b>`;
    }

    return `You are about to delete the "${this.parseRemoveRuleName(rule)}" rule. <br/><br/> Are you sure you want to proceed?`;
  }

  public parseRemovePOIs(rule: Rule): string {
    return rule ? rule.pointsOfInterest.map((poi: PointOfInterest) => poi.name).join(', ') : '';
  }

  public parseRemoveRuleName(rule: Rule): string {
    return rule ? rule.name : '';
  }

  public removeRule(rule: Rule) {
    this.ruleToBeRemoved = rule;
    this.shouldRemove = true;
  }
}
