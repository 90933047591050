

























































































































































import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { NS_ALERTS, NS_POI, NS_STATIONS } from '@/constants/app.constants';
import { REGIONAL_THRESHOLD_LEVELS } from '@/constants/filters.constants';
import { PointOfInterest } from '@/models/position.model';
import { Rule } from '@/models/rule.model';
import { Station } from '@/models/station.model';
import { Customer } from '@/models/customer.model';
import { Options } from '@/models/app.model';
import { RulesService } from '@/services/rules.service';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import StationsSelectTree from '@/components/shared/StationsSelectTree.component.vue';

@Component({
  name: 'RuleDetailsView',
  components: {
    LoadingBar,
    StationsSelectTree,
  },
})
export default class RuleDetailsView extends Vue {
  @Getter('getAllStations', { namespace: NS_STATIONS }) stations?: Station[];
  @Getter('getCustomers', { namespace: NS_STATIONS }) customers?: Customer[];
  @Getter('getAllPointsOfInterest', { namespace: NS_POI }) pointsOfInterest?: PointOfInterest[];
  @Action('fetchStations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Action('fetchCustomers', { namespace: NS_STATIONS }) public fetchCustomers: any;
  @Action('fetchPointsOfInterest', { namespace: NS_POI }) public fetchPointsOfInterest: any;
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;

  public isLoading: boolean = false;
  public isNew: boolean = false;
  public isClone: boolean = false;
  public ruleId: number = -1;
  public rule: Rule = {
    name: '',
    description: '',
    customerId: '',
    regionalEnabled: false,
    regionalPgaThresholdWeak: 0,
    regionalPgaThresholdMedium: 0,
    regionalPgaThresholdStrong: 0,
    regionalPgaThresholdType: 'WEAK',
    stationEnabled: false,
    minAlertingStations: 0,
    stationPgaThreshold: 0,
    stationQualityThreshold: 0,
    stationIds: [],
    pointsOfInterest: [],
    default: false,
  };
  public regionalThresholdLevels: Options<string>[] = REGIONAL_THRESHOLD_LEVELS;

  public mounted() {
    this.isNew = this.$route.params.ruleId === 'new';
    this.isClone = this.$route.name === 'rules-clone';

    this.fetchStations();
    this.fetchCustomers();
    this.fetchPointsOfInterest();
    if (!this.isNew) {
      this.ruleId = +this.$route.params.ruleId;
      RulesService.find(this.ruleId).then((rule) => {
        this.rule = this.isClone
          ? _.extend({}, _.omit(rule, ['id']), {
              name: `${rule.name} clone`,
            })
          : rule;
      });
    }
  }

  public save() {
    this.isLoading = true;
    RulesService.save(this.rule).then(
      () => {
        this.isLoading = false;
        this.addAlert({
          type: 'success',
          header: this.isClone ? 'Rule cloned successfully' : 'Rule saved successfully',
          message: 'You will be redirected to rules list',
          timeout: 5000,
        });
        this.$router.push({ name: 'rules-list' });
      },
      (error) => {
        this.isLoading = false;
        this.addAlert({
          type: 'error',
          header: 'There was a problem saving the Rule',
          message: error.message,
          timeout: 5000,
        });
      },
    );
  }
}
