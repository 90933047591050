






































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { NS_POI, NS_STATIONS } from '@/constants/app.constants';
import { Station } from '@/models/station.model';
import { PointOfInterest } from '@/models/position.model';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import PointOfInterestCard from '@/components/points-of-interest/PointOfInterestCard.component.vue';
import PointOfInterestAddDialog from '@/components/points-of-interest/PointOfInterestAddDialog.component.vue';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';

@Component({
  name: 'PointsOfInterestView',
  components: {
    LoadingBar,
    PointOfInterestAddDialog,
    PointOfInterestCard,
    RealTimeMap,
  },
})
export default class PointsOfInterestView extends Vue {
  @Getter('getAllStations', { namespace: NS_STATIONS }) stations?: Station[];
  @Getter('getAllPointsOfInterest', { namespace: NS_POI }) pointsOfInterest?: PointOfInterest[];
  @Getter('getPointsOfInterestLoading', { namespace: NS_POI }) isLoading?: boolean;
  @Action('fetchStations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Action('fetchPointsOfInterest', { namespace: NS_POI }) public fetchPointsOfInterest: any;

  public addNew: boolean = false;

  public mounted() {
    this.fetchStations();
    this.fetchPointsOfInterest();
  }

  public onPOIAdded() {
    this.fetchPointsOfInterest();
  }
}
