var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-footer',{staticClass:"mb-3 py-2 px-2",staticStyle:{"border-radius":"4px"}},[_c('v-row',{attrs:{"align-content":"center","align":"center"}},[_c('v-col',{staticClass:"col--justify-end"},[_c('v-btn',{attrs:{"small":"","color":"primary","link":"","to":{ name: 'rules-details', params: { ruleId: 'new' } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Rule ")],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-10 rules-list-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.rules,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.thresholds",fn:function(ref){
var rule = ref.item;
return [_c('span',{class:_vm.getThresholdIndex(rule) <= 0 ? 'success--text' : 'grey--text'},[_vm._v(_vm._s(rule.regionalPgaThresholdWeak))]),_vm._v(" / "),_c('span',{class:_vm.getThresholdIndex(rule) <= 1 ? 'success--text' : 'grey--text'},[_vm._v(_vm._s(rule.regionalPgaThresholdMedium))]),_vm._v(" / "),_c('span',{class:_vm.getThresholdIndex(rule) <= 2 ? 'success--text' : 'grey--text'},[_vm._v(_vm._s(rule.regionalPgaThresholdStrong))])]}},{key:"item.pointsOfInterest",fn:function(ref){
var rule = ref.item;
return [_c('span',[_vm._v(_vm._s(rule.pointsOfInterest.length))])]}},{key:"item.regionalPgaThresholdType",fn:function(ref){
var rule = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getThresholdName(rule)))])]}},{key:"item.actions",fn:function(ref){
var rule = ref.item;
return [_c('section',{staticClass:"table__actions"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":"","icon":"","link":"","to":{ name: 'rules-details', params: { ruleId: rule.id } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"secondary","small":"","icon":"","link":"","to":{ name: 'rules-clone', params: { ruleId: rule.id } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-strategy")])],1)]}}],null,true)},[_c('span',[_vm._v("Clone")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.removeRule(rule)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}}])})],1)],1),_c('ConfirmationDialog',{attrs:{"open":_vm.shouldRemove,"header":"Confirm Rule Removal","dismiss-label":"Cancel","confirm-label":"Remove","hide-confirm-button":_vm.ruleToBeRemoved ? _vm.ruleToBeRemoved.pointsOfInterest.length : false,"message":_vm.getRemoveMessage(_vm.ruleToBeRemoved)},on:{"update:open":function($event){_vm.shouldRemove=$event},"onConfirm":_vm.onRemove}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }