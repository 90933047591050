var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pb-16"},[_c('LoadingBar',{attrs:{"is-loading":_vm.isLoading}}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v(" Info ")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"prepend-icon":"mdi-tag-outline","label":"Name","messages":touched || valid ? '' : 'The Name is required',"error-messages":errors},model:{value:(_vm.rule.name),callback:function ($$v) {_vm.$set(_vm.rule, "name", $$v)},expression:"rule.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-textarea',{attrs:{"label":"Description","prepend-icon":"mdi-comment-account-outline","messages":touched || valid ? '' : 'The Description is required',"error-messages":errors},model:{value:(_vm.rule.description),callback:function ($$v) {_vm.$set(_vm.rule, "description", $$v)},expression:"rule.description"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-select',{staticClass:"mb-4",attrs:{"items":_vm.customers,"item-value":"id","item-text":"name","label":"Customer","disabled":!_vm.isNew,"prepend-icon":"mdi-account-tie","messages":touched || valid ? '' : 'The field is required',"error-messages":errors},model:{value:(_vm.rule.customerId),callback:function ($$v) {_vm.$set(_vm.rule, "customerId", $$v)},expression:"rule.customerId"}})]}}],null,true)}),_c('v-switch',{attrs:{"label":"Default Rule"},model:{value:(_vm.rule.default),callback:function ($$v) {_vm.$set(_vm.rule, "default", $$v)},expression:"rule.default"}})],1)],1),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_c('v-switch',{model:{value:(_vm.rule.stationEnabled),callback:function ($$v) {_vm.$set(_vm.rule, "stationEnabled", $$v)},expression:"rule.stationEnabled"}}),_vm._v(" Station Rules ")],1),(_vm.rule.stationEnabled)?_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"Min PGA Threshold","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"type":"number","prepend-icon":"mdi-satellite-uplink","label":"Min PGA Threshold","messages":touched || valid ? '' : 'The field is required',"error-messages":errors},model:{value:(_vm.rule.stationPgaThreshold),callback:function ($$v) {_vm.$set(_vm.rule, "stationPgaThreshold", $$v)},expression:"rule.stationPgaThreshold"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Min Quality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"type":"number","prepend-icon":"mdi-diamond-stone","label":"Min Quality","messages":touched || valid ? '' : 'The field is required',"error-messages":errors},model:{value:(_vm.rule.stationQualityThreshold),callback:function ($$v) {_vm.$set(_vm.rule, "stationQualityThreshold", $$v)},expression:"rule.stationQualityThreshold"}})]}}],null,true)}),_c('StationsSelectTree',{attrs:{"model":_vm.rule.stationIds,"stations":_vm.stations},on:{"update:model":function($event){return _vm.$set(_vm.rule, "stationIds", $event)}}})],1):_vm._e()],1),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_c('v-switch',{model:{value:(_vm.rule.regionalEnabled),callback:function ($$v) {_vm.$set(_vm.rule, "regionalEnabled", $$v)},expression:"rule.regionalEnabled"}}),_vm._v(" Regional Rules ")],1),(_vm.rule.regionalEnabled)?_c('v-card-text',[_c('h4',[_vm._v("PGA Thresholds")]),_c('ValidationProvider',{attrs:{"name":"Weak Threshold","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"type":"number","prepend-icon":"mdi-network-strength-1","label":"Weak Threshold","messages":touched || valid ? '' : 'The field is required',"error-messages":errors},model:{value:(_vm.rule.regionalPgaThresholdWeak),callback:function ($$v) {_vm.$set(_vm.rule, "regionalPgaThresholdWeak", $$v)},expression:"rule.regionalPgaThresholdWeak"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Medium Threshold","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"type":"number","prepend-icon":"mdi-network-strength-3","label":"Medium Threshold","messages":touched || valid ? '' : 'The field is required',"error-messages":errors},model:{value:(_vm.rule.regionalPgaThresholdMedium),callback:function ($$v) {_vm.$set(_vm.rule, "regionalPgaThresholdMedium", $$v)},expression:"rule.regionalPgaThresholdMedium"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Strong Threshold","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mb-8",attrs:{"type":"number","prepend-icon":"mdi-network-strength-4","label":"Strong Threshold","messages":touched || valid ? '' : 'The field is required',"error-messages":errors},model:{value:(_vm.rule.regionalPgaThresholdStrong),callback:function ($$v) {_vm.$set(_vm.rule, "regionalPgaThresholdStrong", $$v)},expression:"rule.regionalPgaThresholdStrong"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Min Level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-select',{staticClass:"mb-4",attrs:{"items":_vm.regionalThresholdLevels,"item-value":"value","item-text":"name","label":"Min Level","prepend-icon":"mdi-network-strength-4-alert","messages":touched || valid ? '' : 'The field is required',"error-messages":errors},model:{value:(_vm.rule.regionalPgaThresholdType),callback:function ($$v) {_vm.$set(_vm.rule, "regionalPgaThresholdType", $$v)},expression:"rule.regionalPgaThresholdType"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Min Alerting Stations","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"type":"number","prepend-icon":"mdi-nut","label":"Min Alerting Stations","messages":touched || valid ? '' : 'The field is required',"error-messages":errors},model:{value:(_vm.rule.minAlertingStations),callback:function ($$v) {_vm.$set(_vm.rule, "minAlertingStations", $$v)},expression:"rule.minAlertingStations"}})]}}],null,true)})],1):_vm._e()],1),_c('v-footer',{staticClass:"font-weight-medium",attrs:{"fixed":""}},[_c('v-col',{staticClass:"text-sm-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"disabled":invalid || _vm.rule.stationEnabled ? !_vm.rule.stationIds.length : false,"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-code-greater-than")]),_vm._v(" "+_vm._s(_vm.isClone ? 'Clone' : 'Save')+" ")],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }